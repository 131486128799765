// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wJgwAu9e{position:absolute;width:100%;height:100%;overflow:hidden;color:#2f2f2f;font-size:6px;writing-mode:vertical-rl;line-height:140%}`, "",{"version":3,"sources":["webpack://./src/styles/components/Background.module.scss"],"names":[],"mappings":"AAAA,UACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,aAAA,CACA,aAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".root {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  color: #2F2F2F;\n  font-size: 6px;\n  writing-mode: vertical-rl;\n  line-height: 140%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `wJgwAu9e`
};
export default ___CSS_LOADER_EXPORT___;
