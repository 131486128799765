import axios from "axios";
import { useAuth } from "@/store/auth";

const $api = axios.create({ baseURL: __API_URL__ });

$api.interceptors.request.use((config) => {
  const { token } = useAuth.getState();

  if (token) config.headers.Authorization = `Token ${token}`;

  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    if (error.response.status === 401 && error.config) {
      useAuth.getState().setToken(null);
      useAuth.getState().setAuthError(true);
    }
    throw error;
  }
);

export default $api;
