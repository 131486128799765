import React from "react";

export const ChevronIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.63076 12C4.2754 12 3.97335 11.8426 3.7246 11.5279L0.273182 7.16306C0.175458 7.04447 0.104386 6.92816 0.0599667 6.81414C0.0199889 6.70011 0 6.58153 0 6.45838C0 6.18472 0.0888395 5.95895 0.266519 5.78107C0.444198 5.60319 0.668518 5.51425 0.939478 5.51425C1.24597 5.51425 1.50361 5.6488 1.71238 5.9179L4.60411 9.68073L10.2476 0.49943C10.3631 0.31699 10.4831 0.189282 10.6074 0.116306C10.7318 0.0387685 10.8873 0 11.0738 0C11.3448 0 11.5669 0.0866591 11.7401 0.259977C11.9134 0.433295 12 0.654504 12 0.923603C12 1.03307 11.9822 1.14253 11.9467 1.252C11.9112 1.36146 11.8556 1.47548 11.7801 1.59407L5.54359 11.5006C5.33037 11.8335 5.0261 12 4.63076 12Z"
        fill="#00FF02"
      />
    </svg>
  );
};

export const LoaderIcon = () => {
  return <div className="lds-dual-ring" />;
};

export const ArrowIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0785138 6.36997L0.0785138 8.36016L12.0196 8.36016L12.0196 10.3503L14.0098 10.3503L14.0098 8.36016L16 8.36016L16 6.36997L14.0098 6.36997L14.0098 4.37979L12.0196 4.37979L12.0196 6.36997L0.0785138 6.36997ZM10.0294 2.3896L12.0196 2.3896L12.0196 4.37979L10.0294 4.37979L10.0294 2.3896ZM10.0294 2.3896L8.03926 2.3896L8.03926 0.399414L10.0294 0.399414L10.0294 2.3896ZM10.0294 12.3405L12.0196 12.3405L12.0196 10.3503L10.0294 10.3503L10.0294 12.3405ZM10.0294 12.3405L8.03926 12.3405L8.03926 14.3307L10.0294 14.3307L10.0294 12.3405Z"
        fill="currentColor"
      />
    </svg>
  );
};
