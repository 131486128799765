// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.bFcuHIfo{max-width:100%;width:100%;align-self:flex-end;border-bottom-left-radius:0;border-bottom-right-radius:0;transition:.2s ease-in-out;opacity:0;transform:translateY(100%)}[data-open=true] div.bFcuHIfo{opacity:1;transform:translateY(0)}`, "",{"version":3,"sources":["webpack://./src/ui/modals/BottomModal/BottomModal.module.scss"],"names":[],"mappings":"AAAA,aACE,cAAA,CACA,UAAA,CACA,mBAAA,CAEA,2BAAA,CACA,4BAAA,CAEA,0BAAA,CACA,SAAA,CACA,0BAAA,CAIA,8BACE,SAAA,CACA,uBAAA","sourcesContent":["div.container {\n  max-width: 100%;\n  width: 100%;\n  align-self: flex-end;\n\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n\n  transition: 0.2s ease-in-out;\n  opacity: 0;\n  transform: translateY(100%);\n}\n\n[data-open='true'] {\n  div.container {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bFcuHIfo`
};
export default ___CSS_LOADER_EXPORT___;
