import cls from "@/styles/components/ProgressBarInfo.module.scss";
import { CommonModal } from "@/ui/modals/CommonModal/CommonModal";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Step, StepData, stepsData } from "@/components/ProgressBarInfo/Steps";
import { motion } from "framer-motion";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const preloadImages = (steps: StepData[]): StepData[] => {
  return steps.map((step) => {
    const img = new Image();
    img.src = step.image;
    return {
      ...step,
      preloadedImage: img,
    };
  });
};

export const ProgressBarInfo: React.FC<Props> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState(0);

  const [preloadedSteps, setPreloadedSteps] = useState([]);

  useEffect(() => {
    const preloaded = preloadImages(stepsData);
    setPreloadedSteps(preloaded);
  }, []);

  useEffect(() => {
    // Preload images
    const loadImages = async () => {
      const promises = stepsData.map(
        (step) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = step.image;
            img.onload = resolve;
          })
      );
      await Promise.all(promises);
    };

    loadImages();
  }, []);

  const onNext = () => {
    if (step === stepsData.length - 1) {
      onClose();
      setStep(0);
      return;
    }
    setStep((p) => p + 1);
  };

  const onPrev = () => {
    if (step === 0) return;
    setStep((p) => p - 1);
  };

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: { offset: { x: number } }
  ) => {
    const swipeThreshold = 100;
    if (info.offset.x < -swipeThreshold && step !== stepsData.length - 1) {
      setDirection(-1);
      onNext();
    } else if (info.offset.x > swipeThreshold) {
      setDirection(1);
      onPrev();
    }
  };

  return (
    <>
      <CommonModal isOpen={isOpen} close={onClose}>
        <div className={cls.modal}>
          <div className={cls.title}>Reward Guide</div>
          <motion.div
            drag="x"
            key={step}
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={handleDragEnd}
            initial={{ x: direction === 1 ? -300 : 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: direction === 1 ? 300 : -300, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className={cls.content}>
              <Step {...preloadedSteps[step]} />
            </div>
          </motion.div>
          <button type="button" className={cls.btn} onClick={onNext}>
            {step === stepsData.length - 1 ? "OK" : "Next"}
          </button>
          <div className={cls.dots}>
            {[...Array(stepsData.length)].map((_, i) => (
              <div
                key={_}
                className={cn(cls.dot, { [cls.active]: step === i })}
              />
            ))}
          </div>
        </div>
      </CommonModal>
    </>
  );
};
