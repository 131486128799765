import React from "react";
import cls from "@/styles/components/FriendsList.module.scss";

export const EmptyFriends = () => {
  return (
    <div className={cls.empty}>
      <div className={cls.emptyTitle}>No frens yet!</div>
      <div className={cls.emptyText}>
        Invite some friends to get started and earn rewards together.
      </div>
    </div>
  );
};
