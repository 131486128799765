import cls from "@/styles/components/Logo.module.scss";
import LogoSvg from "@/assets/images/logo.svg";
import LogoNgSvg from "@/assets/images/logo_ng.svg";
import React, { useEffect, useRef, useState } from "react";
import { useGlitch } from "react-powerglitch";

export const Logo = () => {
  return (
    <div className={cls.logo}>
      <LogoSvg width="20vh" height="20vh" />
    </div>
  );
};

const DURATION = 2000;

export const LogoMain = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showNgLogo, setShowNgLogo] = useState(false);

  const glitch = useGlitch({
    playMode: "manual",
    timing: {
      duration: DURATION,
      easing: "ease-in-out",
    },
    shake: {
      velocity: 30,
    },
  });

  const glitchNg = useGlitch({
    playMode: "manual",
    timing: {
      duration: DURATION,
      easing: "ease-in-out",
    },
    shake: {
      velocity: 30,
    },
  });

  const playAnimation = () => {
    setTimeout(() => {
      glitch.startGlitch();
      glitchNg.startGlitch();
    }, DURATION / 2);
    setTimeout(() => {
      setShowNgLogo((p) => !p);
    }, DURATION);
    setTimeout(() => {
      glitch.stopGlitch();
      glitchNg.stopGlitch();
    }, DURATION * 1.5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      playAnimation();
    }, DURATION * 2);

    return () => clearInterval(interval);
  }, [glitch]);

  return (
    <>
      <div
        style={{ display: showNgLogo ? "none" : "flex" }}
        className={cls.logoWrapper}
      >
        <div className={cls.logo} ref={glitch.ref}>
          <LogoSvg />
        </div>
      </div>
      <div
        style={{ display: !showNgLogo ? "none" : "flex" }}
        className={cls.logoWrapper}
      >
        <div className={cls.logo} ref={glitchNg.ref}>
          <LogoNgSvg />
        </div>
      </div>
    </>
  );
};
