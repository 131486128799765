import $api from "@/api/index";
import { useCallback, useEffect, useState } from "react";

export type ProgressBarI = {
  dividers_bottom_bar: number[];
  dividers_top_bar: number[];
  total_users: number;
  current_invitee_reward: number;
};

export async function getProgressBarData(): Promise<ProgressBarI> {
  const res = await $api.get("/progress_bar");

  return res.data;
}

export function useProgressBar(): [ProgressBarI | undefined, boolean] {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<ProgressBarI>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getProgressBarData();

      setData(r);
    } catch (error) {
      console.log(error);
      // showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
