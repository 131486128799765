import React from "react";
import Lottie from "react-lottie";
import animationData from "@/assets/loading.json";
import cls from "@/styles/components/Loader.module.scss";

export const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={cls.root}>
      <Lottie options={defaultOptions} />
    </div>
  );
};
