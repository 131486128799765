import React from "react";
import cls from "@/styles/components/TasksCommunity.module.scss";

export const TasksCommunity = () => {
  return (
    <div className={cls.root}>
      <div className={cls.title}>NextGen Society</div>
      <div className={cls.text}>Connect, share, grow.</div>
      <a href="https://t.me/nextgen_community" className={cls.btn}>
        Join
      </a>
    </div>
  );
};
