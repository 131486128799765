import React, { createContext, useContext, useEffect, useState } from "react";
import $api from "@/api";
import { useAuth } from "@/store/auth";

type UserI = {
  id: number;
  chat_id: number;
};

type UserDataContextType = {
  userData: UserI | null;
};

const UserDataContext = createContext<UserDataContextType | undefined>(
  undefined
);

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userData, setUserData] = useState<UserI | null>(null);
  const { token } = useAuth();

  const getData = async () => {
    if (!token || !!userData) return;
    try {
      const res = await $api.get("/user/");
      if (res.data[0]) {
        setUserData(res.data[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, [token]);

  return (
    <UserDataContext.Provider value={{ userData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
