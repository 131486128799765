import React, { createContext, useContext, useState } from "react";
import {
  checkTaskApi,
  receiveRewardApi,
  startTaskApi,
  TaskI,
  useTasks,
} from "@/api/tasks";
import { showAlert } from "@/utils/network";
import { useDataContext } from "@/store/DataProvider";

type TasksDataContextType = {
  tasks: TaskI[] | null;
  tasksLoading: boolean;
  checkTask: (taskId: number) => void;
  startTask: (taskId: number) => void;
  receiveReward: (taskId: number) => void;
  loadingTaskId: number | null;
};

const TasksDataContext = createContext<TasksDataContextType | undefined>(
  undefined
);

export const TasksDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tasks, tasksLoading, loadTasks] = useTasks();
  const { loadBalance } = useDataContext();
  const [loadingTaskId, setLoadingTaskId] = useState<number | null>(null);

  const checkTask = async (taskId: number) => {
    setLoadingTaskId(taskId);
    try {
      await checkTaskApi(taskId);
    } catch (error) {
      console.log(error);
      showAlert({ error });
    } finally {
      await loadTasks();
      setLoadingTaskId(null);
    }
  };

  const startTask = async (taskId: number) => {
    setLoadingTaskId(taskId);
    try {
      await startTaskApi(taskId);
    } catch (error) {
      showAlert({ error });
    } finally {
      await loadTasks();
      setLoadingTaskId(null);
    }
  };

  const receiveReward = async (taskId: number) => {
    setLoadingTaskId(taskId);
    try {
      await receiveRewardApi(taskId);
    } catch (error) {
      showAlert({ error });
    } finally {
      await loadTasks();
      await loadBalance();
      setLoadingTaskId(null);
    }
  };

  return (
    <TasksDataContext.Provider
      value={{
        tasks,
        tasksLoading,
        startTask,
        checkTask,
        receiveReward,
        loadingTaskId,
      }}
    >
      {children}
    </TasksDataContext.Provider>
  );
};

export const useTasksDataContext = () => {
  const context = useContext(TasksDataContext);
  if (!context) {
    throw new Error(
      "useTasksDataContext must be used within a TasksDataProvider"
    );
  }
  return context;
};
