// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ixlcxDmj{z-index:201;position:absolute;top:0;left:0;width:100%;padding:12px 40px 12px 16px;background:rgba(206,206,206,.5019607843);text-align:center;font-size:14px;font-weight:500;color:#fff !important}.ixlcxDmj[data-type=info]{background:rgba(206,206,206,.5019607843)}.ixlcxDmj button{position:absolute;right:16px;top:50%;transform:translateY(-50%);display:flex;align-items:center;justify-content:center}.ixlcxDmj button svg{width:18px;height:18px}`, "",{"version":3,"sources":["webpack://./src/styles/components/Alert.module.scss"],"names":[],"mappings":"AACA,UACE,WAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CAEA,UAAA,CACA,2BAAA,CAEA,wCAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CAEA,0BACE,wCAAA,CAGF,iBACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,0BAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,qBACE,UAAA,CACA,WAAA","sourcesContent":["@import \"@/styles/colors\";\n.root {\n  z-index: 201;\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  padding: 12px 40px 12px 16px;\n\n  background: #CECECE80;\n  text-align: center;\n  font-size: 14px;\n  font-weight: 500;\n  color: #fff !important;\n\n  &[data-type='info'] {\n    background: #CECECE80;\n  }\n\n  button {\n    position: absolute;\n    right: 16px;\n    top: 50%;\n    transform: translateY(-50%);\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    svg {\n      width: 18px;\n      height: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ixlcxDmj`
};
export default ___CSS_LOADER_EXPORT___;
