import React, { useMemo } from "react";

const ICONS = {
  close: {
    path: (
      <>
        <path
          d="M18.3 5.70011C17.9134 5.31351 17.2866 5.31351 16.9 5.70011L12 10.6001L7.10001 5.70011C6.71342 5.31351 6.08661 5.31351 5.70001 5.70011C5.31341 6.08671 5.31341 6.71351 5.70001 7.10011L10.6 12.0001L5.70001 16.9001C5.31341 17.2867 5.31341 17.9135 5.70001 18.3001C6.08661 18.6867 6.71342 18.6867 7.10001 18.3001L12 13.4001L16.9 18.3001C17.2866 18.6867 17.9134 18.6867 18.3 18.3001C18.6866 17.9135 18.6866 17.2867 18.3 16.9001L13.4 12.0001L18.3 7.10011C18.6866 6.71351 18.6866 6.08671 18.3 5.70011Z"
          fill="currentColor"
        />
      </>
    ),
    viewbox: "0 0 24 24",
  },
};
export type IconsVariants = "close";
export interface PropsI {
  name: IconsVariants;
  width?: number | "auto";
  height?: number | "auto";
  opacity?: number;
  rotate?: number;
  color?: string;
  scaleX?: number;
  scaleY?: number;
  onClick?: () => void;
}

export const Icon: React.FC<PropsI> = ({
  name,
  width,
  height,
  opacity,
  rotate,
  color,
  scaleX,
  scaleY,
  onClick,
}) => {
  const size = ICONS[name].viewbox.split(" ");
  const transform = useMemo(() => {
    let res = "";
    if (rotate) res += `rotate(${rotate}deg) `;
    if (scaleX) res += `scaleX(${scaleX}) `;
    if (scaleY) res += `scaleY(${scaleY}) `;
    return res;
  }, [rotate, scaleX, scaleY]);

  const style = {
    transform,
    color: color || "",
  };
  return (
    <svg
      style={style}
      width={width || size[2]}
      height={height || size[3]}
      viewBox={ICONS[name].viewbox}
      fill={color || "none"}
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {ICONS[name].path}
    </svg>
  );
};
