import $api from "@/api/index";
import { useAuth } from "@/store/auth";
import { useCallback, useEffect, useState } from "react";

export type BalanceI = {
  tokens_balance: number;
  usdt_balance: number;
  course: number;
};

export async function getBalance(): Promise<BalanceI> {
  const res = await $api.get(`user/balance/`);

  return res.data;
}

export function useBalance(): [
  BalanceI | undefined,
  boolean,
  () => Promise<void>
] {
  const { token } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<BalanceI>();

  const load = useCallback(async () => {
    if (!token) return;
    setLoading(true);

    try {
      const r = await getBalance();

      setData(r);
    } catch (error) {
      console.log(error);
      // showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading, load];
}
