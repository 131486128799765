import cls from "@/styles/components/FriendsList.module.scss";
import React, { useEffect, useState } from "react";
import LogoSvg from "@/assets/images/logo.svg";
import { FriendI, useFriends } from "@/api/freinds";
import { EmptyFriends } from "@/components/FriendsList/EmtpyFriends";
import { Loader } from "@/components/Loader/Loader";
import { removePreloader } from "@/utils/helpers";

const FriendItem: React.FC<{ friend: FriendI }> = ({ friend }) => {
  return (
    <div className={cls.item}>
      <div className={cls.image}>
        {friend.image ? (
          <img src={friend.image} alt={friend.username} />
        ) : (
          <LogoSvg width="100%" height="100%" />
        )}
      </div>
      <div className={cls.info}>
        <div className={cls.name}>{friend.username}</div>
        <div className={cls.token}>{friend.tokens} $GEN</div>
      </div>
    </div>
  );
};

export const FriendsList = () => {
  const [friends, isLoading] = useFriends();

  const [preloaderRemoved, setPreloaderRemoved] = useState(false);

  useEffect(() => {
    if (!isLoading && !preloaderRemoved) {
      removePreloader();
      setPreloaderRemoved(true);
    }
  }, [isLoading, preloaderRemoved]);

  if (isLoading) return <Loader />;
  if (!friends || friends.length === 0) return <EmptyFriends />;

  return (
    <div className={cls.wrapper}>
      <div className={cls.title}>{friends.length} Frens</div>
      <div className={cls.list}>
        {friends.map((f) => (
          <FriendItem friend={f} />
        ))}
      </div>
    </div>
  );
};
