// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oS_Cize6{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%}.CjjJa2l6{display:flex;flex-direction:column;align-items:center;justify-content:center;gap:1rem}.jAE2AjwV{color:#fff;font-size:2rem}.TuThVIRn{color:#8d8c91}`, "",{"version":3,"sources":["webpack://./src/styles/components/TasksBalance.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CAGF,UACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAGF,UACE,UAAA,CACA,cAAA,CAGF,UACE,aAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.balance {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n}\n\n.value {\n  color: #FFFFFF;\n  font-size: 2rem;\n}\n\n.tokenName {\n  color: #8D8C91;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `oS_Cize6`,
	"balance": `CjjJa2l6`,
	"value": `jAE2AjwV`,
	"tokenName": `TuThVIRn`
};
export default ___CSS_LOADER_EXPORT___;
