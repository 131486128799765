import React from "react";

export const PixelCircle: React.FC<{ checked?: boolean }> = ({ checked }) => {
  if (checked)
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.97921 8.47074H4.29678V10.1532H5.97921V11.8356H7.66164V10.1532H9.34407V8.47074H11.0265V6.78831H12.7089V5.10588H11.0265V6.78831H9.34407V8.47074H7.66164V10.1532H5.97921V8.47074Z"
          fill="#00FF02"
        />
        <path
          d="M12.6352 0.858154H4.22301V2.54058H2.54058V4.22301H0.858154V12.6352H2.54058V14.3176H4.22301V16H12.6352V14.3176H14.3176V12.6352H16V4.22301H14.3176V2.54058H12.6352V0.858154ZM12.6352 2.54058V4.22301H14.3176V12.6352H12.6352V14.3176H4.22301V12.6352H2.54058V4.22301H4.22301V2.54058H12.6352Z"
          fill="#00FF02"
        />
      </svg>
    );

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6352 0.858154H4.22301V2.54058H2.54058V4.22301H0.858154V12.6352H2.54058V14.3176H4.22301V16H12.6352V14.3176H14.3176V12.6352H16V4.22301H14.3176V2.54058H12.6352V0.858154ZM12.6352 2.54058V4.22301H14.3176V12.6352H12.6352V14.3176H4.22301V12.6352H2.54058V4.22301H4.22301V2.54058H12.6352Z"
        fill="#353535"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      width="3"
      height="12"
      viewBox="0 0 3 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.125"
        y="3.65381"
        width="2.25"
        height="8.32031"
        fill="currentColor"
        fillOpacity="0.4"
      />
      <rect
        x="0.125"
        y="0.0258789"
        width="2.25"
        height="2.25"
        fill="currentColor"
        fillOpacity="0.4"
      />
    </svg>
  );
};
