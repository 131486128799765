import { useUserDataContext } from "@/store/UserDataProvider";
import { useTonWallet } from "@tonconnect/ui-react";
import { useCallback, useEffect } from "react";
import $api from "@/api/index";

export const useConnectWallet = () => {
  const { userData } = useUserDataContext();
  const wallet = useTonWallet();
  const localWallet = localStorage.getItem("savedWallet");

  const connect = useCallback(async () => {
    if (!userData?.id) return;
    if (!wallet) {
      localStorage.removeItem("savedWallet");
      return;
    }
    if (localWallet === wallet.account.address) return;

    const res = await $api.patch(`/user/${userData.id}/`, {
      wallet_data: wallet.account,
      wallet_address: wallet.account.address,
    });

    localStorage.setItem("savedWallet", wallet.account.address);
  }, [wallet, userData, localWallet]);

  useEffect(() => {
    connect();
  }, [connect]);

  return connect;
};
