import React from "react";
import cls from "@/styles/pages/FriendsPage.module.scss";
import { InviteButton } from "@/components/InviteButton/InviteButton";
import { FriendsList } from "@/components/FriendsList/FriendsList";

export const FriendsPage = () => {
  return (
    <>
      <div className={cls.wrapper}>
        <FriendsList />
      </div>
      <div className={cls.invite}>
        <InviteButton />
      </div>
    </>
  );
};
