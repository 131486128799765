// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D8YrOZUH{height:100%;display:flex;position:relative;background:rgba(0,0,0,.5019607843)}.wN4juIk9{display:flex;flex-direction:column;justify-content:space-between;gap:.5rem;z-index:2;width:100%;height:100%}.kSI_XW0a{position:absolute;top:.25rem;left:.25rem;font-size:.5rem;z-index:2;background:#333;color:#8d8c91}`, "",{"version":3,"sources":["webpack://./src/styles/components/App.module.scss"],"names":[],"mappings":"AAEA,UACE,WAAA,CACA,YAAA,CACA,iBAAA,CACA,kCAAA,CAGF,UACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,SAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CAGF,UACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,eAAA,CACA,aAAA","sourcesContent":["@import \"@/styles/colors\";\n\n.wrapper {\n  height: 100%;\n  display: flex;\n  position: relative;\n  background: #00000080;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 0.5rem;\n  z-index: 2;\n  width: 100%;\n  height: 100%;\n}\n\n.id {\n  position: absolute;\n  top: 0.25rem;\n  left: 0.25rem;\n  font-size: 0.5rem;\n  z-index: 2;\n  background: #333333;\n  color: #8D8C91;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `D8YrOZUH`,
	"container": `wN4juIk9`,
	"id": `kSI_XW0a`
};
export default ___CSS_LOADER_EXPORT___;
