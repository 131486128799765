import React from "react";
import cls from "@/styles/components/Balance.module.scss";
import { useDataContext } from "@/store/DataProvider";

export const Balance = () => {
  const { balance } = useDataContext();

  return (
    <div className={cls.root}>
      <div className={cls.info}>
        {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
        <div className={cls.infoText}>// Your balance</div>
      </div>
      <div className={cls.mid}>
        <div className={cls.coins}>{balance.tokens_balance} $GEN</div>
        <div className={cls.usdt}>≈ {balance.usdt_balance} $USDT</div>
      </div>
      <div className={cls.info}>
        <div className={cls.infoText}>1 $GEN = {balance.course} $USDT</div>
      </div>
    </div>
  );
};
