import React from "react";
import cls from "@/styles/components/TasksBalance.module.scss";
import { useDataContext } from "@/store/DataProvider";

export const TasksBalance = () => {
  const { balance } = useDataContext();

  return (
    <div className={cls.root}>
      <div className={cls.coin} />
      <div className={cls.balance}>
        <div className={cls.value}>{balance.tokens_balance}</div>
        <div className={cls.tokenName}>GEN</div>
      </div>
    </div>
  );
};
