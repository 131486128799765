import cn from "classnames";
import cls from "@/styles/components/TasksList.module.scss";
import React from "react";
import { TaskI } from "@/api/tasks";
import { useTasksDataContext } from "@/store/TasksProvider";
import { ArrowIcon, ChevronIcon } from "@/components/TasksList/icons";

const ButtonLoader = () => {
  return <div className="lds-dual-ring" />;
};

export const TaskStartButton: React.FC<{ task: TaskI }> = ({ task }) => {
  const { startTask, loadingTaskId } = useTasksDataContext();
  const disabled = loadingTaskId === task.id;
  if (task.link) {
    return (
      <button
        className={cn(cls.btn)}
        disabled={disabled}
        onClick={() => startTask(task.id)}
        type="button"
      >
        <a href={task.link} target="_blank" rel="noreferrer">
          {loadingTaskId === task.id ? <ButtonLoader /> : "Start"}
        </a>
      </button>
    );
  }
  return (
    <button
      className={cn(cls.btn)}
      type="button"
      disabled={disabled}
      onClick={() => startTask(task.id)}
    >
      {loadingTaskId === task.id ? <ButtonLoader /> : "Start"}
    </button>
  );
};

export const TaskCheckButton: React.FC<{ task: TaskI }> = ({ task }) => {
  const { checkTask, loadingTaskId } = useTasksDataContext();
  const disabled = loadingTaskId === task.id;

  return (
    <button
      className={cn(cls.btn)}
      type="button"
      disabled={disabled}
      onClick={() => checkTask(task.id)}
    >
      {loadingTaskId === task.id ? <ButtonLoader /> : "Check"}
    </button>
  );
};

export const ReceiveRewardButton: React.FC<{ task: TaskI }> = ({ task }) => {
  const { receiveReward, loadingTaskId } = useTasksDataContext();
  const disabled = loadingTaskId === task.id;

  return (
    <button
      className={cn(cls.btn, cls.complete)}
      type="button"
      disabled={disabled}
      onClick={() => receiveReward(task.id)}
    >
      {loadingTaskId === task.id ? <ButtonLoader /> : "Claim"}
    </button>
  );
};

export const TaskCompletedButton: React.FC<{ task: TaskI }> = ({ task }) => {
  if (task.link) {
    return (
      <a
        className={cn(cls.btn, cls.complete)}
        href={task.link}
        target="_blank"
        rel="noreferrer"
      >
        <ArrowIcon />
      </a>
    );
  }
  return (
    <button className={cn(cls.btn, cls.complete)} type="button">
      <ChevronIcon />
    </button>
  );
};
