import React from "react";

export const TasksIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.548584 0.59375H16V14.5H0.548584V0.59375ZM14.4548 12.9549V2.13889H2.09372V12.9549H14.4548ZM5.184 3.68403H3.63886V5.22917H5.184V3.68403ZM6.72914 3.68403H12.9097V5.22917H6.72914V3.68403ZM5.184 6.7743H3.63886V8.31944H5.184V6.7743ZM6.72914 6.7743H12.9097V8.31944H6.72914V6.7743ZM5.184 9.86458H3.63886V11.4097H5.184V9.86458ZM6.72914 9.86458H12.9097V11.4097H6.72914V9.86458Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const FriendsIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.13047 0.290527H3.26762V1.57814H1.98001V5.44099H3.26762V6.72861H7.13047V5.44099H3.26762V1.57814H7.13047V0.290527ZM7.13047 1.57814H8.41808V5.44099H7.13047V1.57814ZM0.048584 9.30384H1.3362V11.8791H9.06189V13.1667H0.048584V9.30384ZM1.3362 9.30384H9.06189V8.01622H1.3362V9.30384ZM10.3495 9.30384H9.06189V13.1667H10.3495V9.30384ZM9.7057 0.290527H12.2809V1.57814H9.7057V0.290527ZM12.2809 5.44099H9.7057V6.72861H12.2809V5.44099ZM12.2809 1.57814H13.5685V5.44099H12.2809V1.57814ZM15.5 9.30384H14.2124V11.8791H11.6371V13.1667H15.5V9.30384ZM11.6371 8.01622H14.2124V9.30384H11.6371V8.01622Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const WalletIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3097 0.493652H0.548584V12.5999H12.6548V9.90962H14V3.18393H12.6548V0.493652H11.3097ZM11.3097 9.90962V11.2548H1.89372V1.83879H11.3097V3.18393H5.92914V9.90962H11.3097ZM12.6548 8.56448H7.27428V4.52907H12.6548V8.56448ZM9.96455 5.87421H8.61942V7.21935H9.96455V5.87421Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 0H6.5V1.5H5V3H3.5V4.5H2V6H0.5V7.5H2V15H7.25V10.5H8.75V15H14V7.5H15.5V6H14V4.5H12.5V3H11V1.5H9.5V0ZM9.5 1.5V3H11V4.5H12.5V6H14V7.5H12.5V13.5H10.25V9H5.75V13.5H3.5V7.5H2V6H3.5V4.5H5V3H6.5V1.5H9.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
