import React from "react";
import HamsterSvg from "@/assets/images/hamster.svg";
import OnboardingSvg from "@/assets/images/onboarding.svg";
import cls from "@/styles/components/Onboarding.module.scss";
import { useDataContext } from "@/store/DataProvider";

export const StepOne = () => {
  return <HamsterSvg />;
};

export const StepTwo = () => {
  return <OnboardingSvg />;
};

const getNumberLetter = (val: number) => {
  if (val / 1000 < 1000) return `${val / 1000} K`;
  return `${val / 1000000} M`;
};

export const StepThree = () => {
  const { progressBarData } = useDataContext();

  if (!progressBarData) return "";

  const { dividers_top_bar, dividers_bottom_bar } = progressBarData;
  return (
    <div className={cls.table}>
      <div className={cls.row}>
        <div className={cls.header_cell}>
          Total <br /> Users
        </div>
        <div className={cls.header_cell}>Tokens per Referral</div>
        <div className={cls.header_cell}>Tokens for Joining</div>
      </div>
      {dividers_top_bar.map((_, i) => {
        return (
          <div className={cls.row}>
            <div className={cls.cell}>
              {getNumberLetter(
                dividers_bottom_bar
                  .slice(0, i + 1)
                  .reduce((acc, g) => acc + g, 0)
              )}
            </div>
            <div className={cls.cell}>{dividers_top_bar[i]}</div>
            <div className={cls.cell}>{dividers_top_bar[i] / 2}</div>
          </div>
        );
      })}
    </div>
  );
};
