import cls from "@/styles/components/TasksList.module.scss";
import React, { useEffect, useState } from "react";
import LogoSvg from "@/assets/images/logo.svg";
import { TaskI, TaskStatus } from "@/api/tasks";
import {
  ReceiveRewardButton,
  TaskCheckButton,
  TaskCompletedButton,
  TaskStartButton,
} from "@/components/TasksList/TaskButtons";
import { useTasksDataContext } from "@/store/TasksProvider";
import { Loader } from "@/components/Loader/Loader";
import { removePreloader } from "@/utils/helpers";

const TaskButton: React.FC<{ task: TaskI }> = ({ task }) => {
  if (task.status === "not_started") {
    return <TaskStartButton task={task} />;
  }
  if (task.status === "started") {
    return <TaskCheckButton task={task} />;
  }
  if (task.status === "waiting_transaction") {
    return <ReceiveRewardButton task={task} />;
  }

  return <TaskCompletedButton task={task} />;
};

const statusOrder: Record<TaskStatus, number> = {
  waiting_transaction: 1,
  not_started: 2,
  started: 3,
  completed: 4,
};

const TaskItem: React.FC<{ task: TaskI }> = ({ task }) => {
  return (
    <div className={cls.item}>
      <div className={cls.image}>
        {task.image ? (
          <img src={task.image} alt={task.name} />
        ) : (
          <LogoSvg width="100%" height="100%" />
        )}
      </div>
      <div className={cls.info}>
        <div className={cls.name}>{task.name}</div>
        <div className={cls.token}>{task.token_count} $GEN</div>
      </div>
      <TaskButton task={task} />
    </div>
  );
};

export const TasksList = () => {
  const { tasks, tasksLoading } = useTasksDataContext();

  const [preloaderRemoved, setPreloaderRemoved] = useState(false);

  useEffect(() => {
    if (!tasksLoading && !preloaderRemoved) {
      removePreloader();
      setPreloaderRemoved(true);
    }
  }, [tasksLoading, preloaderRemoved]);

  return (
    <div className={cls.wrapper}>
      <div className={cls.title}>Tasks</div>
      {tasksLoading ? (
        <Loader />
      ) : (
        <div className={cls.list}>
          {tasks
            ?.sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
            .map((t) => (
              <TaskItem task={t} key={t.id} />
            ))}
        </div>
      )}
    </div>
  );
};
