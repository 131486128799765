// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gNfWXhVC{display:flex;flex-direction:column;gap:.25rem;padding:.75rem}.p4idPiin{padding:0 1rem;display:flex;flex-direction:row;justify-content:center;align-items:center}.YCQ2FFM9{font-size:1.125rem;line-height:140%;color:#4f4f4f}.wFfOJnod{font-size:2rem;line-height:100%;color:#353535}.qocVgbpM{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:.5rem}.SeC8wYDs{display:flex;flex-direction:row;align-items:center;color:#fff;font-size:2.5rem;font-weight:300}.Uzy1jEyR{display:flex;flex-direction:row;align-items:center;color:#fff;font-size:1.125rem}`, "",{"version":3,"sources":["webpack://./src/styles/components/Balance.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,cAAA,CAGF,UACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,UACE,kBAAA,CACA,gBAAA,CACA,aAAA,CAGF,UACE,cAAA,CACA,gBAAA,CACA,aAAA,CAGF,UACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CAEF,UACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CACA,gBAAA,CACA,eAAA,CAEF,UACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n  padding: 0.75rem;\n}\n\n.info {\n  padding: 0 1rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.infoText {\n  font-size: 1.125rem;\n  line-height: 140%;\n  color: #4F4F4F;\n}\n\n.star {\n  font-size: 2rem;\n  line-height: 100%;\n  color: #353535;\n}\n\n.mid {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5rem;\n}\n.coins {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: #FFFFFF;\n  font-size: 2.5rem;\n  font-weight: 300;\n}\n.usdt {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: #FFFFFF;\n  font-size: 1.125rem;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `gNfWXhVC`,
	"info": `p4idPiin`,
	"infoText": `YCQ2FFM9`,
	"star": `wFfOJnod`,
	"mid": `qocVgbpM`,
	"coins": `SeC8wYDs`,
	"usdt": `Uzy1jEyR`
};
export default ___CSS_LOADER_EXPORT___;
