// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.COUtsolk{flex:1;display:flex;flex-direction:column;align-items:center;justify-content:center;gap:1rem}.aH0WmAer{font-size:1.5rem;color:#fff}`, "",{"version":3,"sources":["webpack://./src/styles/pages/UnauthorizedPage.module.scss"],"names":[],"mappings":"AAAA,UACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAGF,UACE,gBAAA,CACA,UAAA","sourcesContent":[".wrapper {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n}\n\n.text {\n  font-size: 1.5rem;\n  color: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `COUtsolk`,
	"text": `aH0WmAer`
};
export default ___CSS_LOADER_EXPORT___;
