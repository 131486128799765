import cls from "@/styles/components/WalletButton.module.scss";
import navCls from "@/styles/components/Nav.module.scss";
import { WalletIcon } from "@/features/Nav/icons";
import React, { useState } from "react";
import { CommonModal } from "@/ui/modals/CommonModal/CommonModal";
import CoinsSvg from "@/assets/images/coins.svg";
import { SlidingButton } from "@/ui/SlidingButton/SlidingButton";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useConnectWallet } from "@/api/wallet";
import cn from "classnames";

type Props = {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
};

export const WalletButton: React.FC<Props> = ({ isOpen, setOpen }) => {
  const [tonConnect] = useTonConnectUI();
  const x = useConnectWallet();

  const wallet = useTonWallet();

  const [slide, setSlide] = useState(false);

  const onConnect = () => {
    setSlide(true);
    tonConnect.openModal();
    setTimeout(() => {
      setSlide(false);
    }, 300);
  };

  const onDisconnect = () => {
    setSlide(true);
    tonConnect.disconnect();
    setTimeout(() => {
      setSlide(false);
    }, 300);
  };

  return (
    <>
      <button
        type="button"
        className={cn(navCls.link, {
          [navCls.active]: isOpen,
        })}
        onClick={() => setOpen(true)}
      >
        <WalletIcon />
        <div className={navCls.linkText}>Wallet</div>
      </button>
      <CommonModal isOpen={isOpen} close={() => setOpen(false)}>
        <div className={cls.modal}>
          <div className={cls.title}>Wallet</div>
          <div className={cls.image}>
            <CoinsSvg width="100%" height="auto" />
          </div>
        </div>
        {!wallet?.account && (
          <SlidingButton full={slide} onClick={onConnect}>
            Link Wallet
          </SlidingButton>
        )}
        {!!wallet?.account && (
          <SlidingButton full={slide} onClick={onDisconnect}>
            Disconnect
          </SlidingButton>
        )}
      </CommonModal>
    </>
  );
};
