// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vnyAkPwR{display:flex;flex-direction:column;gap:1rem}.wJnu6E6U{font-size:1.25rem;color:#fff}.IbfLeIAo{max-width:75vw;padding:0 1rem}`, "",{"version":3,"sources":["webpack://./src/styles/components/WalletButton.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,UACE,iBAAA,CACA,UAAA,CAGF,UACE,cAAA,CACA,cAAA","sourcesContent":[".modal {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem\n}\n\n.title {\n  font-size: 1.25rem;\n  color: #FFFFFF;\n}\n\n.image {\n  max-width: 75vw;\n  padding: 0 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `vnyAkPwR`,
	"title": `wJnu6E6U`,
	"image": `IbfLeIAo`
};
export default ___CSS_LOADER_EXPORT___;
