import React, { useEffect, useState } from "react";
import { ProgressBar } from "@/features/ProgressBar/ProgressBar";
import { Balance } from "@/components/Balance/Balance";
import { ActionButtons } from "@/components/ActionButtons/ActionButtons";
import { InviteButton } from "@/components/InviteButton/InviteButton";
import { LogoMain } from "@/components/Logo/Logo";
import { useDataContext } from "@/store/DataProvider";
import { removePreloader } from "@/utils/helpers";

export const MainPage = () => {
  const { dataLoading } = useDataContext();

  const [preloaderRemoved, setPreloaderRemoved] = useState(false);

  useEffect(() => {
    if (!dataLoading && !preloaderRemoved) {
      removePreloader();
      setPreloaderRemoved(true);
    }
  }, [dataLoading, preloaderRemoved]);

  if (dataLoading) return "";

  return (
    <>
      <ProgressBar />
      <Balance />
      <LogoMain />
      <ActionButtons />
      <InviteButton />
    </>
  );
};
