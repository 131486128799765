import "@/styles/base.scss";
import React, { FC, useEffect } from "react";
import cls from "@/styles/components/App.module.scss";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { Alert } from "@/components/Alert/Alert";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import {
  useExpand,
  useInitData,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { Nav } from "@/features/Nav/Nav";
import { useAuth } from "@/store/auth";
import { UserDataProvider } from "@/store/UserDataProvider";
import { DataProvider } from "@/store/DataProvider";
import { Onboarding } from "@/components/Onboarding/Onboarding";

export const App: FC = () => {
  const [initDataUnsafe, initData] = useInitData();
  console.log("initData: ", initData);
  console.log("initDataUnsafe: ", initDataUnsafe);
  const webapp = useWebApp();
  const [isExpanded, expand] = useExpand();
  const navigate = useNavigate();
  const { authError, setAuthError, token, setToken } = useAuth();
  const [searchParams] = useSearchParams();

  const user_token = searchParams.get("user_token");

  useEffect(() => {
    if (user_token) setToken(user_token);
  }, []);

  useEffect(() => {
    if (!token && !user_token) navigate("/unauthorized");
  }, []);

  useEffect(() => {
    if (authError) {
      navigate("/unauthorized");
      setAuthError(false);
    }
  }, [authError]);

  useEffect(() => {
    if (!isExpanded) expand();
  }, []);

  useEffect(() => {
    webapp.disableVerticalSwipes();
  }, []);

  return (
    <TonConnectUIProvider
      manifestUrl={`${window.location.origin}/tonconnect-manifest.json`}
    >
      <UserDataProvider>
        <DataProvider>
          <div className={cls.wrapper}>
            <div className={cls.id}>Telegram id: {initDataUnsafe.user?.id}</div>
            <div className={cls.container}>
              <Outlet />
              {token && <Nav />}
              <Onboarding />
            </div>
            <Alert />
          </div>
        </DataProvider>
      </UserDataProvider>
    </TonConnectUIProvider>
  );
};
