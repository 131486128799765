import cls from "@/styles/components/Background.module.scss";
import React from "react";

export const Background = () => {
  return (
    <div className={cls.root}>
      {"&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&&@&&&&&&&&&&@&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&\n" +
        "@@&&&@&&&&@&&&&&@&&&&&&&&&&&&&@@@&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&\n" +
        "@&&&&@@&@&&&&@&@&&@@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&\n" +
        "&@&@&&@@&&&&&&@@&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&\n" +
        "&@&&@&&&&&@&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&&&&&&&&&&&&&\n" +
        "@@&@@@&&&@&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&\n" +
        "&@@&&@&&&&&&&&&&@&@&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&&&&&&&&&&\n" +
        "@&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&TW&&&&&&&&&&&&&&&W&&&&&&&&&&&&\n" +
        "&@&&&&@@&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&&&&&&T&&&T&TW&&T&&&&&&T\n" +
        "@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&&&&&&&&&&&&&&&&W&&&&&&&&&&&&W&&T&&&&&&&&&T&&W&&T&TW&&&&&&&&&&&&&&&&&&T&WWWTTT&&\n" +
        "&@&@@&&&@@&&&&&&&&&&&&@&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&T&&&&&&&&&&&&&&&&&&&W&&W&&W&&W&&W&&&TW&&T&&TW&&&WT&&T&TTW&TT&&&WTT\n" +
        "&@&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&M&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&&&&&&&&&&&&&&&&&&&W&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&T&&&&&&TT&&&&TTTT&WWT&W&&&T&WT&&T&T&&W&&&&W&T&&TTWT&TTWW@T\n" +
        "&@&&&&@&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&T&&&&&&&&&&&&&&&T&&&&&TW&T&&T&&T&&&WW&T&TWT&&T&WT&TTTW&&TWW&WTW&WW&TTWWTTTWTTWWTTTTT&TTTTT\n" +
        "&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&&&&&T&&&&&&&&&&&&W&&&&&&W&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&W&&&&&T&W&W&&T&&TT&T&&&&&&&W&WWW&WTWWTWT&&T&WWWTTWW&WTWTTWWTT&TWTWTTTT}W&WTWWWTTT&T&W!TTTTWTTTTTWTT&TTWT\n" +
        "&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&T&T&W&TW&&&W&T&&&&T&&&&&&&W&&T&&T&&&&T&@&&&&&&&&&&&&&&&&&&&&&T&&&&&W&&&&&&&&&T&&&&&&&&&&&WW&&&W&&&&&&&&&&&W&&&&&&&&W&&&&&WWTWWT&&W&&&&WW&&@T&WT&WT&TW&&TT&T&WTTT&TTTTWWTTTTWTTTTTTTWTTTTTTT!TT!T&TTTTTWTTTWTTTWT!TWTTTTT!TTWTTTT\n" +
        "&&&&&&&&&&&&&&&@@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&&T&&W&T&WT&&TTWTWTTTWT&&&TTT&&WT&W&WT&&W&W&&&&&&&&T&&&TWT&&&&&W&&&WW&&&&&T&&&&&&&W&&&&&&&&&&W&&&W&&&&&&&&&W&T&&&&&&&&&&&TT&&&&&&W&T&&W&&TWWWWT&&T&WTTTWW}TT&TT&WWW&T&&TWTTTTTTWTWW&W}TTWT}WTTTWTTWTTT}WTWTWTTTWWTT!T}TWTTTTWT!TT!!TTTTTTT!!WT\n" +
        "&&&&&&&@@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&TW&T&WT&WWT&TTTTW&TT&T&&TWTT&T&WTWWT&W&WTT&WWW&W&&&WTWW&&&WWT&&&&&W&&W&W&WW&T&TWT&&&&&&&T&&WT&W&WT&&&&&TW&&W&&&TT&WW&WTWWW&&&WW&T&&&&WTT&&T&WWTTTTTWT@TT&WW&TWW}TTTTWWWTWWWTTTTTTW&}!TWTTTTTTTTTTWTTTTTTTTTTW!TTTTT}!T!!!!TTTTTTTTTWTT!TWT!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&TW&&&&&T&&&TTT&WT&TWTTW&TWWTTWTWTWTWTWTWWT&TWWTTTWTTTW&WWTWW&TW&&&WTT&TW&WW&WWWTT&&WW&TT&&&T&W&&WTTT&&&TTW&WWT&&WWWT&&&&TTW&&&TT&WT&WT&&&}WTWWWTTTWTWWTTTWWTTTTWTTWWWTTTTWWTWTWTTTTTTTTTTTTTTTTTT}TTTTTTT!TTTTTT!!TTT!!TTTW!!TT!TT!T!!!T!T!}!!!TT!!!!TT\n" +
        "&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&W&TW&TT&TWT&&TTTTTWWWWTWTWWTWTWT&TTTTTWTTTT!TTTTTTTT&TT&T&TTT}WTTWT&TT&TT&TTWWTWWT&&T&&W&TTWTT&TTWTW&}TTTT&TTTWTTTTW&TWT&TWTT&TTTTTWWTTWTTTTTTWTTT&!T&TTTT&TTWTWT&T!TTTTTTWW}!TTTTT!T!TWTTTT!T!TTTTT}T!T!TTT!T!!TTW!T!!!!T}TTTT!!!T!T!!T!TT!TWT!!!!T!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&T&TT&WTWTTTTTW&TTWTTTTTTTTTTTWTTTTWWTWTTT!TTWTTWTTTTTWTTTTWTT}WTWTTWTTWTWTTWTTWWTTWWWWW&TTTTWTTTTTTWTTTWW}WWTTTTWTTWWTTTTT&WWT}WTTTTTWWWTT}W}}T!TT}!&TTTTWWWWW!!WTT}!TTT!TW!!!!!TTWT!TTTTT}TT!TTTT!TTTTT!T!TTTTT!TTT!!W!TTTTT!}!!!!!!TT!T!T!!TT!T!!!T!!\n" +
        "&&&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&&&TWTTWTTTWTTTWTTWTTTTT}TWT}WTTTTT!!!WW!T!TTTTT!TTW}!TT}TTTT!TTT!WTT!TTTTTTTTTTTTTWTTTTWTTTTTW!TT}TWTWTTTTTTTTTTTTTTTTTWTTTTTWTTTWWT!WTTTTTTTWTTWTWTT}TTTTTTTTTTTT!!}T!}T!T!W}TT!!!}T!!T!T!!TTTT!TTTTTTTW!!!!T!!!TTTTTTTT!TT!!!!!!T!TT!!!!!!!!!!T!!!T!!!!T!!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&TT&&WTTWTWTTWTWTTW}!TTTT!TTWTTTT}TTTT!WTT!TTTTTW!TT!TT!TTTWT!TTTTTTT!TTTTTW!TWTTTTTTTTTTTWTTWTTTTTTWTWTT}TTWTTTTTWWTT!TT}TTWTTTTTTTTW!!TTT}TTT!TWW!TT!!TTT!T!}T!TTT!T!TTTTT!!TT}T!T!T!T!TTT!!!TTTTTTTT!T!T!T!!T!!!T!T!!!!!!TT!!TT!!T!T!!!!T!TTT!!T!T!!!TTTT!}!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&&W&&&&TTTTWTWTTTWTTTTTTWT!TT!!!TTT!TTTTTTTT!!TT!!TTT!!T!!TTTTTTTTTTTTTTT!!TWTWTWTTWT}T!WTTTTTTW}TTTT!TTWTTT!TTW!WTT!}TT!TTTT!T!!!!TT!W}TW!WTT!T!TT!T!!T!!T}TT!TT!TT!TT!!!TTTW!!T!T!!T!!TTTT!!!!!!TT!!TTT!T!!T!T!TTT!!!!!!T!!T!!!!!!!!!TTT!T!!!!!!!!T!!T!T!!!T!T!TT!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&W&&&&&&WTWTWTTTWTTTWT!TW!TT!T!T!TTTT!!!!W!T!T!!T!T!!!TWT!T!!TTT}TT!T!T!TTT!!!!}!TTT!!TT!TTT!!!WWT}T}!TTT!TTT!TTT}TTTTT!!TTTTTT}!WTTT!T!!TTTTTTT!TT!T!T!T!}TTTTT!!T!T!T!!!T!TTWTTT!!!T!!TT!!!T!!T!T!T!TT!T!!T!!T!T!!T!!!!!!!!!!!!!!!!!T!!!T!TT!!!!!!!T!!TT!!!!!!!!!!!TT!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&TWT&T&WWWTTTWTWTWT}WTWTTTTTTTTT!TTTT!TTTTTTTW!!!!!T!!T!!!T!!TT!!W!!TT!WT!!T!TW!TT!!!!!!T!T!!T!TT!TWTT!T!!!WTT}TT!TTT!T!TTT!T!T!!TT!TTT!T!T!!!!!!!!T!!!T!TT!!!!T!!!!!TT!!!!!TT!T!T!T!!!!!!!TTT!!!!T!T!!!!!T!T!!!!!!TT!!!!!!!!TT!T!!!T!!T!T!!!!T!!T!!T!!!!!!!!!!!!!T!!T!!!T\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&WWW&T&TTTWTTTTTTWT}WTW!T!!T!!}T!!!WTT!T!T!TTTT!!!TT!T}!T!!!!!!!!!!!!TTT!T!!!!!!!TT!!!!!T!!TTT!TT!TT!T!T!!!T}!!TT!T!!!!!!T!TT!!!!!T!!!!!!!}!TT!TT!!T!!TT!!!T!!T!T!!!!!T!T!T!!T!TTTT!T!T!TT!TT!TTTT!T!T!!!!TTT!!!!!!!!!T!T!!TT!!!!!T!!!T!!!T!!!!!!T!T!!+!!T!!!!!!!!!!!!!!!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&T&TTTT&&TTTTTWWTT!TTT!TTT!TTT!T!T!T!T!!!!!!T!!!TT!T!!!!!!!T!!!!T!!!TTT!!!T!!!TTTT!T!TT!!TT!!!TT!T!!!!T!!!!TTTT!!!!}!TTTT!!!!!!!TT!!!TTT!!!!!!!!!T!!!!!TT!T!!!!TT!!!!!T!!!!!!!!!!T!!!!!!!T!!!!!!TT!!T!!!!!TT!T!!T!!!!!!!!T!!!!!!!T!!!T!!!!!!T!!!!!+!T!!T+!!!!!!+!!!!+!!!!!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&WWT&WTTTWTTT}TTTTTTWTTWTTTTT!!!T!T!!!TW!!!!TTT!T!!!!!!T!!!T!}!!!T!!!!T!!TT!T!!!!TT!!!!!!!!TT!T!TTT!!!T!!!!T!!!!!!!!!!!T!!!!!!!!!TT!!TT!!!!T!TT!!!!!!T!!T!!!T!!!!!!T!!!!!!!T!T!!T!!T!TT!T!!TT!T!T!TT!!!!!!!!!!TT!!!T!!T!!!!!!!!!!T!T!!!!TT!!!!!T!!!!!!!+!!!!!!!!T!!!!!+!!!!!!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&W&W&TTTW&TWTTTTTW}!TTT!!TTTT!T!T!T!T!!!T!T!T!!!!!!!!T!!!!!!TTT!!T!!!!!!!!T!TTTT!TT!!!!T!!!!!T!!!!!!T!!!!!!TT!!!!!!!!!!!!T!T!T!!!!!!T!!!T!!!!!!!!!T!!!!T!!!T!!!!T!!!TT!!!!!!!!!!!TT!!!!!!!TT!!!!T!!!T!!!!!TT!!T!T!!!T!!!!!!T!!!!!!!!!!!!!!+!!!!!+!!!!!!!!!!!!!!!!!!!+!++!!!!+!!+!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&&&&&&TWTTTTTT&TWTTTT!TTTTT!!TTT!T!!T!!!T!T!T!!!!!!!!!!T!!!!!T!!!!!!T!!T!TT!!T!TTT!!!!!T!!!!!!!TT!!T!TT!!!!!!!!!!T!!!!!!!T!!T!!!!!!!!!!!!!T!!T!!!!T!!!!!!!T!T!!!!!!!T!!!!!!!!!T!!!!T!T!!!!!!!T!!T!!!!!T!!!!!!!T+T++!+!!!!+!!!!!!!!!+!+!!!!!+!!!++!!!!!+!+!!!!!!!!!!+!!!!!!+++!!+!!++!+\n" +
        "&&&&&&&&&&&&&&&&&&&&&W&&T&&T&TTTTWTWTTWWTTT!!!!!T!!W!!!!T!T!!!!!!!!!!!T!!!!TT!!TT!!!!!!!T!!!!!!!!T!!!T!!!T!T!T!T!!!T!!!!!!TT!!!!TT!!T!T!T!!!!!!!!!!!!T!!!!!!!T!!!!!TT!TT!!!!!!!!!TT!!!!T!!!!!!!!!!!T!!!T!+!+!!!T!!!!!!!T!!!+!!!!!!!!!+!!!!!!!!!!+!!!!!!!!!+!!!!!+!!!!!!+!!!!!++++!!!++!!++++!+!!!!!!!!++!+!!\n" +
        "&&&&&&&&&&&&&&&W&&&&&&WTT&TTTTTWWTTTTTTTWTT}!TT!T!!!!!!!!!!!!!!!!!!TT!T!!!T!!!!!!!!!!!!!T!T!!!T!!!!!T!!!T!!!!!!!!!!!!!T!!TT!!!!!!!!!!!!!!!T!!!TT!!!!T!!!!!!!T!T!!!!!!!!!!!!!!!!T!!!!!!!!T!!!!T!!!T!!!!+!!++++!!!!+!+!!!+!!!!+!!!!+!+!++!!!+!!+!!+!!!+!!!+++!+!!+!!!!++!+++!!!!!!+!!++!!+!+!!!!+!++!'!+!!+!!!\n" +
        "&&&&&&&&&&&&&&&&&&&&&&&T&TTTTWTTWTWTTT!T!!!!!!T!!!!!T!!!!!!!T!T!!TT!!!!!!!!!T!!!!!!!!!!!!!!!!!!!!T!!!!!!!TT!!!T!!!!!T!!T!!!!!!!!!!!!!!TT!!!!TT!!!!!!!!!!!!T!!!T!!!!!!!!!!!!!!!!!!T!!!!!T!!!!!!T!!!!!!++!!!!!!+!!+!!!!!++!!!!!+!+!!!!!+++!!!++!+++++!+!+!+!+!+++!++!!!++++!!!!!+!'+!+!!++!'!+++'++++!!_''+!++\n" +
        "&&&&&&&&&&&&&&&&&&&W&W&TTTWTTWTTTTTTTT!TT!!TT!!!!T!TTT!!!!T!T!TT!!!!!!T!!!T!!!!!!!T+!!!!!!!!!!!!T!!!!!!!!T!!!TT!T!!!!!!!!!!!!!!T!T!T!T!T!T!!!!!!!!!!T!!!+!!!!!!!!!!!!!+T+!!!+!!!!!!!!!!!!++!!!!!!+!!!!!!!!!++!!!!+!!!++++!!!!!!+!+!!!+!!+!++!+!!!+++!!+!!++!!!!++!+!++\"!+!!++++!!!_'!'!_!!!+''+!'!'!+''!!''\"\n" +
        "&&&&&&&&&&&&&&&&&&&&&TWWWTTT&TTTTTW!WTT!TT!TT!!TT!!TTT!!!!T!!T!T!!!!!!!!!!!!!!!T!T!T!!!!!!!T!!!T!!!!!!!TT!!T!!T!T!!!!!!T!!!!!!!!T!!!!!!!T!TT!!!!!!!T!!!!!!T!!!!!!!!!!!!!!!!!++!!!!!!!+!!++++!!!+!!!!!!!!++!!+!+!++!!!++!++++!+!!!!!+++!+!!!!+'!+!!!'++!'++!!+'+!''!-'+-+'_!_+_+!'!_!__,!!_._+'!__'_--_!-\"_!_\n" +
        "&&&&&&&@&&&&&&W&T&&&W&TTTTTTTTTTTT}!T!!TT!!!T!!T!!!T!!!!!!!T!!T!T!!!!!!!TT!!!!!!!!!!!!!!!T!!!!T!!T!!+!!T!!!!!T!!T!!!T!TT!!!T!!!!!!!T!!!!!!!!!!!!!!!!!!!!!+!!!!!!!!!!!!++!!!!!!!!+!+!!!!!!++++!+!+!+!++!++!!+++++++!!!!+!++++!!!!!'!!!!++_+!+'!'!'+++!-__+\"_''__''!_-'__'__''''__'+''\"'+!_____'__'______'_'__\n" +
        "&&&&&&&&&&&&&&&&&T&&&TTWTW}WT!WT!TT!TT!T!!T!!!T!T!T!!T!!TTT!!!!!!!!!!!!!!!!!!!!!!T!!!!!!!!+!!!!+!T!!!!!!!TT!!T!!T!!!!!!!!!!!!!!!!+!!!!T!+!!!!!!!!!!!+!!!!!!!!!!!!+!!+!!+!!!!!!!!!!+!!!!!!!+!+!+!+!+!!!!++!+!!++!++\"+++!!!++!_+'+!++!!!!!!'''_'.'+_'__-__'\"_'_'''_''_'____-__'_'''__'______'_______'_________\n" +
        "&&&&&&&&&&&&&&&&WTWTTWTWW}TTTTTTT!!!T!!TT!!TTT!T!!!!T!!T!T!T!!!!!!T!!!!!!!T!!!!!!!!T!!!!!!!!!!!!!T!!!!!!!!!!!!!!+!!T!!!!!!+!TT!!!!+!!!!!!!!!!+!!!!!!!!+!!!!++!!+!!!!!+!!++!!!++++!+++++!!+++'!!+++!!+!+++!+'+!!'!+'+!'''\"+++!___+!!'_!'__''__+_\"_'_-___'_''________'_-_________'_'__'_______________________\n" +
        "&&&&&&&&&&&&&&W&W&W&WTWWTTTTTTTT!!!!!!T!!!T!!T!!!!!!T!!!!!!TTT!!T!!!!!!!!!!!!!+!!+!!!!+!!!!!!!!!+!+!!!!!!T+!T+!!!!+!+!!!!!!!!!!!!!+!!!!!!!!+!!+!+!!++!++!!!!++!!!!+!!!++!!+!+!!+!!!!++!!+!!!+!!'++!!!+!\"__++!!!+'!''!__''''+_+____''___'_'-___\"__''''___'___________________'__'___________________'________\n" +
        "&&&&&&&&&&&&&&&W&&W&WW}TTWTT!TTT!!}!T!!T!!T!T!!T!T!!!!!!!!!!!!!!!!T!!!+!!+!+!++!+!!+!!!+!!!!!++!!++++!!+!!!!+++++!!!!+!!!!+!!!!!!!!!!+!+!!!!!!+!++++!++!+!!!!+!!+!++!+!!+!+!!+!+!+\"++!++'!+!!+!!!\"_'!++!__+'''_'_'+\"\"'___'___'_'____________''_'___________________________________________'________________\n" +
        "&&&&&&&&&&W&W&&&&TTTTTTTTT!TTT!!TTW!TT!!!T!T!!!!!!TTT!!!!!!T!!!!!+!!!!+!!!!!!++!!!+!+!!!!!!!!+++!!!!+!+!!!!!!!!!+!!!+!++!!+!!+!!++!!+!!+++!!!!+!+!!+!+++!!!++!!!!!+++++!+!!+++'+!!'+!+'!_'_'!!__!!'_'_'-'_!_!__'__'_'-___________'______'_____'__________'__________________________________________________\n" +
        "&&&&&&&&&&&&&&W&TWTTTTTTTT!!!!T!TTT!TT!!!!!!TT!T!!!!!!!T!T!!!!!!!+!!!!!!!+!++!!!++!!++++!++++!+++!!++!+!!!!!+!+!!!!+!!!!!++!+!!+++!!+!+++++++!!!+++!++!+!!!!+!\"'!!+!'!++!'+!!'!_!_++!!'__!!'__''____'____!_'___''_______'___________________________________________________________________________________\n" +
        "&&&&&&&&&&&&&T&&&&&TWWTT!TTTTT!!T!!TT!!!!!TT!!!!!!!!T!!+!!T!!!!!!!!+!!!!+++!!!+!!!!!++++++!!+!++!!!+++!!+!!!!!++!!!++++!+!!!++++!!!+!++!++++++++++!+!+!!++'+!!!!+!+++_+!!+!!_!!_''__''''!'__'-_______'______________________________________________________________________________________________________\n" +
        "&&&&&&&&&&&&&TWWTWWWTTTT!T!T!TT!T!TTT!!!T!T!!!!!T!!T!!!+!!!+!!+!!!!!+!++++!+!+!++++!!!+!+!+!++++!++!!+!++++!!+++!!!+++++!+!+!!+++!+!+++!!'!+++++!!!!!+'!!!!'!+!!'_!!_!___!_+__-+''_!__--'______________'____________________________________________________________________________________________________\n" +
        "&&&&&&&&&&&&TT&&TTTTTWWTTTT!TT!!!!T!!!!!!!!!!!!!!T!!!!!!!!!!!++!!!+!!+!!+!+++!+!!+++!!++++++!+++++++++++!!!+++!!+++++!+!+!++!\"!!+++!++!++++!'+!++!!+!+_'+'_''+'!!'_'__'+'!'''_______-_________'_____________________________________________________________________________________________________________\n" +
        "&&&&&&&&&&W&&&TW&WTTTTWTTTTT!!!!!TTT!!!TT!!!!T!!!!T!!!!!+!++!!!++++!!!+!++!!++!++++!+!!!+++!++!+!!!!!+++++!+!!!!+++!+++++++!!!++!!\"+!!!+!+'!+!+!+++!!+''!!'_!+!'!'_____________________'____________________________________________________________________________________________________________________\n" +
        "&&&&&&&&&&&TT&TWWTTTTTTT!TTW!T!!!!!TT!!!T!!!!!T!!!!!!!!!!!+!+!!!!!+!!++!!+!!++!+!++!!+!!+!+!+!!+++++!!!!++++!!!+!+!++++++++++!+!+!!++!+!!!+!+++!!+'_!_!''--!'_'__'_'__.'''___'__''__________________________________________________________________________________________________________________________\n" +
        "&&&&&&&&&&&&&W&TTWTTTWWTTTT!T!!TT!!!!!!!!T!!!!!!!T!!!!!!!!!+!++++!!++!!++!++!!+!++!++!+!+++++!+++++++++!++++++++++!+++!++!+++!!+'!+++!!++!+'!'__!+'_+\"--_!_-_'_'________''__________________________________________________________________________________________________________________________________\n" +
        "&&&&&&&&&&W&&TW&WTTT!TTT!!!!!!TTT!!!!T!!!!!!TT!T!!!!!!!!!!!!!!!!!!+!!++!!!+!!+++!++++!++!+++++++++++++++++++++!++!'+++!'!'!+++!++''!+!!''!_+'+_+__'_____!_________'_________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&&&&&&WTTW}TTTTWTT!!!!!!!!!!!!T!T!!!!T!T!!!!!!!!!!+!!!!!!!!!!++!+!++!++!+++++++!++++++!!++!+!!++++++!+++++++++!++!!++++_+++++++'!'_''_!_!_!+!'_'__!__'_________________-_______________________________________________________________________________________________________________________________\n" +
        "&W&&&&&&&&TWWTTTWWWTT}TTTTT!!!!!!T!T!TT!!!!TT!!!!!!T!!!!!!!!+!+!!!++!++++!!+++++++!+++!+!+++++++!+++++++++!++!+!+!!++!++!_+++!''+'!''+___!___!__'-___'___-__'_____-_________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&&&&TTTWWT&WTTTT!TT!TTT!!T!TT!!T!!!!!!!TT!!!!!!!!!!!!!!!!+!!++!++++!!!+!+++++++++++!!++++++++++++++!+!!+++'!!!!+!++'_!!_!+!!'++__'+_''+_'___'__'__'_'__________'___'___________________________________________________________________________________________________________________________________\n" +
        "&&&&&&W&&TTTTTT&TTTT}T!!!T!TT!!TT!T!!!T!!!!!!T!T!TT!!!!T!!!+!!!+!+!!++!!++++!+!++++!+++++!+++!!!!+++!+++!+!+++!++!!++++++++_!+'_+___+__'!__+_______-________________________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&W&&&&T&TW&}TTTTW!!TT!!TT!!T!T!!!T!!!T!TT!!!!T!!!!!!+!!!!!+!!+++++++!!++!++!+!+++++++!+++++++!+++++++!+++!+++'+!'++'++_'!+-'!+''!______!_''_______'____________________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&&W&&TWWW&TWTTTTT}!T!!!TT!!!!T!!T!!T!!!!!!!!!!!!!T!!!!+!!++++++!!+!!++!+++!++++++!++++++++!++++++++!!++++++!+!!+_+!!'+!+'!!+++___'_____________________________________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&T&&TWT&&WTTWTWTTTT!W!T!TTT!T!!!!!!!!!!!!!!!!!!!!!!!!!!!!!+!+!+++++++++!+++!+++++++++++!+!!!!+++++!++++++!+++!++!+!++'+!\"!'+__'\"'__+_______'__________'________________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&&&&W&TT&TTTTTTTT!T!TTT!!T!!!!!T!!T!!TT!!!!!T!!++!!!+!!!!+!!+!+!!+++++++++++!++++!+++++++++++!+!+!!++!++!++!+!!++++++!'__'+''!_+_'_________'_______'___________________________________________________________________________________________________________________________________________________\n" +
        "&&&W&&W&TTT&TWTTTT!T!TTTT!!T!!!!TT!!T!T!!!!!T!!!!!!!+!!!!!!!!!!+!++!+!+!!!++!++++!++!+++!+++++++!!+!+++++!++!+++++++'''!++'!+'+++__'___'__'_______'_________________________________________________________________________________________________________________________________________________________\n" +
        "&&&&&&W&W&TWTWTTTTWTTT!!T!TT!!!T!TT!!T!!T!!!!!!!!T!!!!!!!!!!!!+!+!!!+!!!++!!!++++++!+!+++!+++!++++!!++!+++++!+!+'!+!++'!+!++_!_!'+_'+_\"'_''___'_____________________________________________________________________________________________________________________________________________________________\n" +
        "&&&T&&W&W&WWW&WWWW!TTT!!TTT!T!!T!!!!!T!T!!!T!!T!!!!!!!!!!!!!+!!!!++!!!++++!!++!+++++!++++!!+++++++++++!!++++++!+!!++++!++!!\"++_'!_'''______-___________'____________________________________________________________________________________________________________________________________________________\n" +
        "T&&&&&&TT&TTTWTT}T!TTTW!T!T!!!!!!!!!!!!!!!T!!!!!TT!T!!+!!!!!!!!+!!+++!!!+++!+!!+!!++!!+!+!+++!+!++!++!+!+!++++!++!+++++++'++!!+''+___''__!+____________'____________________________________________________________________________________________________________________________________________________"}
    </div>
  );
};
