import React, { useState } from "react";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import cls from "@/styles/components/Onboarding.module.scss";
import { Background } from "@/components/Background";
import cn from "classnames";
import { SlidingButton } from "@/ui/SlidingButton/SlidingButton";
import { StepOne, StepThree, StepTwo } from "@/components/Onboarding/Steps";

const steps = [
  {
    title: "Welcome to NextGen: Get what's yours",
    text:
      "No Tap. No more exploitation. Earn what you deserve, without the grind",
    image: <StepOne />,
  },
  {
    title: "The revolution apps",
    text:
      "You invite friends – We negotiate with exchanges – Exchanges pay us – We pay you",
    image: <StepTwo />,
  },
  {
    title: "Be among the first and earn more",
    text:
      "Once a certain number of users is reached, the reward for each invitee is halved",
    image: <StepThree />,
  },
];

export const Onboarding = () => {
  const [step, setStep] = useState(0);
  const [show, setShow] = useLocalStorage("showOnboarding_1", true);

  const onClose = () => {
    setShow(false);
  };

  const onNext = () => {
    if (step === steps.length - 1) {
      onClose();
      return;
    }
    setStep((p) => p + 1);
  };

  if (!show) return "";

  return (
    <div className={cls.root}>
      <Background />
      <div className={cls.content}>
        <div className={cls.step}>0{step + 1}</div>
        <div className={cls.imageBlock}>{steps[step].image}</div>
        <div className={cls.modalWrapper}>
          <div className={cls.modalGradient} />
          <div className={cls.modal}>
            <div className={cls.textBlock}>
              <div className={cls.title}>{steps[step].title}</div>
              <div className={cls.text}>{steps[step].text}</div>
            </div>
            <div className={cls.bottom}>
              <div className={cls.dots}>
                {[...Array(steps.length)].map((_, i) => (
                  <div
                    key={_}
                    className={cn(cls.dot, { [cls.active]: step === i })}
                  />
                ))}
              </div>
              <div className={cls.btnWrapper}>
                {step === 2 && (
                  <SlidingButton onClick={onClose} fixWidth>
                    Start
                  </SlidingButton>
                )}
                {step < 2 && (
                  <button type="button" className={cls.btn} onClick={onNext}>
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
