import React from "react";

export const WithdrawIcon: React.FC = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.72056 15.9215L8.71074 15.9215L8.71074 3.98037L10.7009 3.98037V1.99019L8.71074 1.99019V0L6.72056 0V1.99019L4.73037 1.99019V3.98037L6.72056 3.98037L6.72056 15.9215ZM2.74019 5.97056L2.74019 3.98037H4.73037L4.73037 5.97056L2.74019 5.97056ZM2.74019 5.97056V7.96074H0.75L0.75 5.97056H2.74019ZM12.6911 5.97056V3.98037H10.7009V5.97056L12.6911 5.97056ZM12.6911 5.97056L12.6911 7.96074H14.6813L14.6813 5.97056H12.6911Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export const StakeIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 12V14H8.25V12H6.25L6.25 10H8.25L8.25 0H10.25L10.25 10H12.25V12H10.25ZM12.25 10V8L14.25 8V10L12.25 10ZM6.25 10L4.25 10L4.25 8H6.25L6.25 10ZM0.25 12.0001V16V18V18.0001H2.25V18L16.25 18V18.0001H18.25L18.25 18V16L18.25 12.0001H16.25V16H2.25L2.25 12.0001H0.25Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};
