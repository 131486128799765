import React from "react";
import cls from "@/styles/pages/TasksPage.module.scss";
import { TasksBalance } from "@/components/TasksBalance/TasksBalance";
import { useDataContext } from "@/store/DataProvider";
import { TasksList } from "@/components/TasksList/TasksList";
import { TasksDataProvider } from "@/store/TasksProvider";
import { TasksCommunity } from "@/components/TasksCommunity/TasksCommunity";
import { Loader } from "@/components/Loader/Loader";

export const TasksPage = () => {
  const { dataLoading } = useDataContext();

  if (dataLoading) return <Loader />;

  return (
    <TasksDataProvider>
      <div className={cls.wrapper}>
        <TasksBalance />
        <TasksCommunity />
        <TasksList />
      </div>
    </TasksDataProvider>
  );
};
