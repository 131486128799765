import React, { useMemo, useState } from "react";
import cls from "@/styles/components/ProgressBar.module.scss";
import { InfoIcon, PixelCircle } from "@/features/ProgressBar/icons";
import cn from "classnames";
import { useDataContext } from "@/store/DataProvider";
import { ProgressBarInfo } from "@/components/ProgressBarInfo/ProgressBarInfo";

type BarProps = {
  goal: number;
  prevGoal: number;
  current: number;
};

const getNumberLetter = (val: number) => {
  if (val / 1000 < 1000) return `${val / 1000} K`;
  return `${val / 1000000} M`;
};

const Bar: React.FC<BarProps> = ({ goal, prevGoal, current }) => {
  const fullCells = Math.floor(
    ((current - prevGoal) / (goal - prevGoal)) * 100
  );
  return (
    <div className={cls.bar}>
      <div className={cls.cell} style={{ width: `${fullCells}%` }} />
    </div>
  );
};

export const ProgressBar = () => {
  const { progressBarData: data } = useDataContext();
  const [infoOpen, setInfoOpen] = useState(false);

  const dividers = useMemo(() => {
    if (!data) return [];
    let total = 0;
    return [
      0,
      ...data.dividers_bottom_bar.map((n) => {
        total += n;
        return total;
      }),
    ];
  }, [data]);

  if (!data) return "";

  const current = data.total_users;
  const refRewards = data.dividers_top_bar;

  const onInfoOpen = () => {
    setInfoOpen(true);
  };

  const onInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <div className={cls.root}>
      <button
        type="button"
        onClick={onInfoOpen}
        className={cn(cls.infoBtn, { [cls.open]: infoOpen })}
      >
        <InfoIcon />
      </button>
      <ProgressBarInfo isOpen={infoOpen} onClose={onInfoClose} />

      <div className={cn(cls.goals, cls.goalsTop)}>
        {refRewards.map((r, i) => {
          return (
            <div className={cls.goal} key={r}>
              <div
                className={cn(cls.goalText, {
                  [cls.checked]: current > dividers[i],
                })}
              >
                {r} $GEN
              </div>
            </div>
          );
        })}
      </div>

      <div className={cls.battery}>
        {[...Array(dividers.length - 1)].map((_, i) => {
          return (
            <Bar
              key={dividers[i + 1]}
              goal={dividers[i + 1]}
              prevGoal={dividers[i]}
              current={current}
            />
          );
        })}
      </div>

      <div className={cn(cls.goals, cls.goalsBottom)}>
        {dividers.map((g) => {
          return (
            <div className={cls.goal} key={g}>
              <div className={cn(cls.goalText, { [cls.checked]: current > g })}>
                {getNumberLetter(g)}
              </div>
              <PixelCircle checked={current > g} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
