// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DKJckjsS{display:flex;justify-content:center;align-items:center;flex:1}.DKJckjsS div{height:100%;max-height:70vw;aspect-ratio:1}.DKJckjsS svg{height:100%;width:100%}.kFQplOz5{width:max-content}`, "",{"version":3,"sources":["webpack://./src/styles/components/Logo.module.scss"],"names":[],"mappings":"AAEA,UACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,MAAA,CAEA,cAAA,WAAA,CAAA,eAAA,CAAA,cAAA,CACA,cAAA,WAAA,CAAA,UAAA,CAGF,UACE,iBAAA","sourcesContent":["@import \"@/styles/colors\";\n\n.logoWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n\n  div { height: 100%; max-height: 70vw; aspect-ratio: 1; }\n  svg { height: 100%; width: 100% }\n}\n\n.logo {\n  width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoWrapper": `DKJckjsS`,
	"logo": `kFQplOz5`
};
export default ___CSS_LOADER_EXPORT___;
