// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PRxpG9xc{display:flex;flex-direction:column;align-items:flex-start;width:100%;padding:1rem;border-radius:1rem;background:rgba(21,21,21,.8980392157);border:1px solid #202020;border-top:none}.lsXH76Tu{color:#fff;font-size:1.125rem;line-height:1.5rem;margin-bottom:.25rem}.kv5DsuUg{color:rgba(255,255,255,.6980392157);font-size:.875rem;line-height:1.25rem;margin-bottom:1rem}.gdKB6Fro{border-radius:.5rem;text-align:center;background:#fff;padding:.5rem .875rem;transition:.2s}.gdKB6Fro:active{background:#cecece;transition:.1s}`, "",{"version":3,"sources":["webpack://./src/styles/components/TasksCommunity.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,qCAAA,CACA,wBAAA,CACA,eAAA,CAGF,UACE,UAAA,CACA,kBAAA,CACA,kBAAA,CACA,oBAAA,CAGF,UACE,mCAAA,CACA,iBAAA,CACA,mBAAA,CACA,kBAAA,CAGF,UACE,mBAAA,CACA,iBAAA,CACA,eAAA,CACA,qBAAA,CACA,cAAA,CAGF,iBACE,kBAAA,CACA,cAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  padding: 1rem;\n  border-radius: 1rem;\n  background: #151515E5;\n  border: 1px solid #202020;\n  border-top: none;\n}\n\n.title {\n  color: #FFFFFF;\n  font-size: 1.125rem;\n  line-height: 1.5rem;\n  margin-bottom: 0.25rem;\n}\n\n.text {\n  color: #FFFFFFB2;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  margin-bottom: 1rem;\n}\n\n.btn {\n  border-radius: 0.5rem;\n  text-align: center;\n  background: #FFFFFF;\n  padding: 0.5rem 0.875rem;\n  transition: 0.2s;\n}\n\n.btn:active {\n  background: #CECECE;\n  transition: 0.1s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PRxpG9xc`,
	"title": `lsXH76Tu`,
	"text": `kv5DsuUg`,
	"btn": `gdKB6Fro`
};
export default ___CSS_LOADER_EXPORT___;
