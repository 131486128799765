import React, { useState } from "react";
import cls from "@/styles/components/Nav.module.scss";
import { FriendsIcon, HomeIcon, TasksIcon } from "@/features/Nav/icons";
import { WalletButton } from "@/components/WalletButton/WalletButton";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";

export const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [walletOpen, setWalletOpen] = useState(false);
  return (
    <div className={cls.root}>
      <div className={cls.nav}>
        <button
          type="button"
          className={cn(cls.link, {
            [cls.active]: location.pathname === "/" && !walletOpen,
          })}
          onClick={() => navigate("/")}
        >
          <HomeIcon />
          <div className={cls.linkText}>Home</div>
        </button>
        <button
          type="button"
          className={cn(cls.link, {
            [cls.active]: location.pathname === "/tasks" && !walletOpen,
          })}
          onClick={() => navigate("/tasks")}
        >
          <TasksIcon />
          <div className={cls.linkText}>Tasks</div>
        </button>
        <button
          type="button"
          className={cn(cls.link, {
            [cls.active]: location.pathname === "/friends" && !walletOpen,
          })}
          onClick={() => navigate("/friends")}
        >
          <FriendsIcon />
          <div className={cls.linkText}>Frens</div>
        </button>
        <WalletButton isOpen={walletOpen} setOpen={setWalletOpen} />
      </div>
    </div>
  );
};
