export function onlyNumbers(value: string, allowedChars = ""): string {
  const reg = new RegExp(`[^0-9${allowedChars}]`, "g");
  return value.trim().replace(reg, "");
}

export const removePreloader = () => {
  const preloader = document.getElementById("preloader");
  if (preloader) {
    preloader.style.zIndex = "0";
  }
};
