import React from "react";
import cls from "@/styles/pages/UnauthorizedPage.module.scss";
import { Logo } from "@/components/Logo/Logo";

export const UnauthorizedPage = () => {
  return (
    <div className={cls.wrapper}>
      <Logo />
      <div className={cls.text}>Press /start in chat</div>
    </div>
  );
};
