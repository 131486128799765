// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wAwTboSN{display:flex;flex-direction:column;padding:1rem;background:#0a0a0a;border-top:1px solid #353535;border-radius:.375rem .375rem 0 0}.As93aq3h{display:flex;flex-direction:row;justify-content:space-between}.JJeXJePR{font-size:2rem;line-height:60%;color:#353535}.pgxwyGfH{display:flex;justify-content:space-around}.tQsBsPYR{width:25%;padding:0 1rem;color:#aaa}.tQsBsPYR svg{margin-bottom:.5rem}.tQsBsPYR.vzZphHtu{color:#00ff02}.w5HsL_Lw{font-size:.675rem}`, "",{"version":3,"sources":["webpack://./src/styles/components/Nav.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,iCAAA,CAGF,UACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,UACE,cAAA,CACA,eAAA,CACA,aAAA,CAGF,UACE,YAAA,CACA,4BAAA,CAGF,UACE,SAAA,CACA,cAAA,CACA,UAAA,CACA,cAAA,mBAAA,CAGF,mBACE,aAAA,CAGF,UACE,iBAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  background: #0A0A0A;\n  border-top: 1px solid #353535;\n  border-radius: 0.375rem 0.375rem 0 0;\n}\n\n.stars {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.star {\n  font-size: 2rem;\n  line-height: 60%;\n  color: #353535;\n}\n\n.nav {\n  display: flex;\n  justify-content: space-around;\n}\n\n.link {\n  width: 25%;\n  padding: 0 1rem;\n  color: #AAAAAA;\n  svg {margin-bottom: 0.5rem}\n}\n\n.link.active {\n  color: #00FF02;\n}\n\n.linkText {\n  font-size: 0.675rem;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `wAwTboSN`,
	"stars": `As93aq3h`,
	"star": `JJeXJePR`,
	"nav": `pgxwyGfH`,
	"link": `tQsBsPYR`,
	"active": `vzZphHtu`,
	"linkText": `w5HsL_Lw`
};
export default ___CSS_LOADER_EXPORT___;
