import React, { useState } from "react";
import cls from "@/styles/components/InviteButton.module.scss";
import { BottomModal } from "@/ui/modals/BottomModal/BottomModal";
import { SlidingButton } from "@/ui/SlidingButton/SlidingButton";
import { showAlert } from "@/utils/network";
import { useDataContext } from "@/store/DataProvider";

export const InviteButton = () => {
  const { link, progressBarData } = useDataContext();
  const [modalOpen, setModalOpen] = useState(false);

  if (!link || !progressBarData) return "";

  const text = `Your access is here! Join and claim ${progressBarData.current_invitee_reward}$`;
  const href = `https://t.me/share/url?url=${link.invite_link}&text=${text}`;

  const onOpen = () => {
    setModalOpen(true);
  };

  const onCopy = () => {
    navigator.clipboard.writeText(link.invite_link);
    showAlert({ text: "Copied successfully!", type: "info" });
  };

  return (
    <div className={cls.root}>
      <SlidingButton full={modalOpen} onClick={onOpen}>
        Invite
      </SlidingButton>
      <BottomModal isOpen={modalOpen} close={() => setModalOpen(false)}>
        <div className={cls.modal}>
          <div className={cls.title}>Invite frens</div>
          <button type="button" className={cls.btn} onClick={onCopy}>
            Copy invite link
          </button>
          <a href={link?.invite_link ? href : ""} className={cls.btn}>
            Share invite link
          </a>
        </div>
      </BottomModal>
    </div>
  );
};
