// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KX9MQOuK{flex:1;margin:1.5rem 1rem;position:relative;overflow-y:scroll}.fVQa5e74{width:100%;position:absolute;bottom:6rem}`, "",{"version":3,"sources":["webpack://./src/styles/pages/FriendsPage.module.scss"],"names":[],"mappings":"AAAA,UACE,MAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CAGF,UACE,UAAA,CACA,iBAAA,CACA,WAAA","sourcesContent":[".wrapper {\n  flex: 1;\n  margin: 1.5rem 1rem;\n  position: relative;\n  overflow-y: scroll;\n}\n\n.invite {\n  width: 100%;\n  position: absolute;\n  bottom: 6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `KX9MQOuK`,
	"invite": `fVQa5e74`
};
export default ___CSS_LOADER_EXPORT___;
