// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y9ws_UA0{display:flex;flex-direction:row;padding:1rem 0;margin:0 1rem 0;border-radius:.5rem;background:#0a0a0a;border-left:1px solid #202020;border-right:1px solid #202020;border-bottom:1px solid #202020}.CuyEu6KQ{height:100%;width:1px;background:#202020}.pcdDJ9IQ{display:flex;flex-direction:column;align-items:center;gap:.5rem;flex:1}.qCe1Lov9{display:flex;justify-content:center;align-items:center}._5IbMAgYS{font-size:.625rem;color:#fff}`, "",{"version":3,"sources":["webpack://./src/styles/components/ActionButtons.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CAEA,kBAAA,CACA,6BAAA,CACA,8BAAA,CACA,+BAAA,CAGF,UACE,WAAA,CACA,SAAA,CACA,kBAAA,CAGF,UACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,SAAA,CACA,MAAA,CAGF,UACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,WACE,iBAAA,CACA,UAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: row;\n  padding: 1rem 0;\n  margin: 0 1rem 0;\n  border-radius: 0.5rem;\n\n  background: #0A0A0A;\n  border-left: 1px solid #202020;\n  border-right: 1px solid #202020;\n  border-bottom: 1px solid #202020;\n}\n\n.divider {\n  height: 100%;\n  width: 1px;\n  background: #202020;\n}\n\n.btnWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 0.5rem;\n  flex: 1;\n}\n\n.btn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.btnText {\n  font-size: 0.625rem;\n  color: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Y9ws_UA0`,
	"divider": `CuyEu6KQ`,
	"btnWrapper": `pcdDJ9IQ`,
	"btn": `qCe1Lov9`,
	"btnText": `_5IbMAgYS`
};
export default ___CSS_LOADER_EXPORT___;
