import $api from "@/api/index";
import { useCallback, useEffect, useState } from "react";

export type FriendI = {
  username: string;
  language_code: string;
  image: string;
  tokens: string;
};

export async function getFriends(): Promise<FriendI[]> {
  const res = await $api.get(`/friends/`);

  return res.data;
}

export function useFriends(): [FriendI[] | undefined, boolean] {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<FriendI[]>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getFriends();

      setData(r);
    } catch (error) {
      console.log(error);
      // showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
