import { devtools, persist } from "zustand/middleware";
import { create } from "zustand";

interface AuthState {
  token: string | null;
  authError: boolean;
  setToken: (token: string) => void;
  setAuthError: (token: boolean) => void;
}

export const useAuth = create<AuthState>()(
  devtools(
    persist(
      (set, get) => ({
        token: null,
        authError: false,
        setToken: (data) => {
          set(() => ({ token: data }));
        },
        setAuthError: (data) => {
          set(() => ({ authError: data }));
        },
      }),
      {
        name: "auth-storage",
      }
    )
  )
);
