import cls from "@/styles/components/ProgressBarInfo.module.scss";
import React, { useEffect, useRef } from "react";

export type StepData = {
  text: string;
  number: number;
  image: string;
  preloadedImage?: HTMLImageElement; // Optional until preloading is complete
};

export const Step: React.FC<StepData> = ({
  text,
  number,
  image,
  preloadedImage,
}) => {
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Insert the preloaded image directly into the DOM
    if (imgRef.current) {
      imgRef.current.appendChild(preloadedImage); // Append the preloaded image
    }
  }, [preloadedImage]);
  return (
    <>
      <div className={cls.image} ref={imgRef}>
        <div className={cls.step}>0{number}</div>
      </div>
      <div className={cls.text}>{text}</div>
    </>
  );
};

export const stepsData = [
  {
    text:
      "Once a certain number of users is reached, the reward for each invitee is reduced",
    number: 1,
    image: "/images/onboarding_rewards.png",
  },
  {
    text: "This scale indicates the number of users in the NextGen App",
    number: 2,
    image: "/images/onboarding_goals.png",
  },
  {
    text: "The user can earn rewards in $GEN",
    number: 3,
    image: "/images/onboarding_rewards.png",
  },
  {
    text:
      "These rewards are awarded for reaching specific user milestones, such as 100K, 1M, 5M, and 100M",
    number: 4,
    image: "/images/onboarding_goals.png",
  },
];
