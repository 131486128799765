// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mRzBB0HB{display:flex;justify-content:center;align-items:center;height:100%}`, "",{"version":3,"sources":["webpack://./src/styles/components/Loader.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `mRzBB0HB`
};
export default ___CSS_LOADER_EXPORT___;
