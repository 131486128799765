import React from "react";
import cls from "@/ui/SlidingButton/SlidingButton.module.scss";
import cn from "classnames";

interface ButtonPropsI {
  children?: React.ReactNode;
}

interface PropsI
  extends ButtonPropsI,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  onClick?: () => void;
  full?: boolean;
  fixWidth?: boolean;
  disabled?: boolean;
}

export const SlidingButton: React.FC<PropsI> = ({
  children,
  onClick,
  disabled,
  full,
  fixWidth,
  ...rest
}) => {
  return (
    <button
      type="button"
      className={cn(cls.button, { [cls.fixWidth]: fixWidth })}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <div className={cn(cls.arrowWrapper, { [cls.arrowFull]: full })}>
        <div className={cls.arrow}>
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.0785138 6.36997L0.0785138 8.36016L12.0196 8.36016L12.0196 10.3503L14.0098 10.3503L14.0098 8.36016L16 8.36016L16 6.36997L14.0098 6.36997L14.0098 4.37979L12.0196 4.37979L12.0196 6.36997L0.0785138 6.36997ZM10.0294 2.3896L12.0196 2.3896L12.0196 4.37979L10.0294 4.37979L10.0294 2.3896ZM10.0294 2.3896L8.03926 2.3896L8.03926 0.399414L10.0294 0.399414L10.0294 2.3896ZM10.0294 12.3405L12.0196 12.3405L12.0196 10.3503L10.0294 10.3503L10.0294 12.3405ZM10.0294 12.3405L8.03926 12.3405L8.03926 14.3307L10.0294 14.3307L10.0294 12.3405Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className={cls.text}>{children}</div>
    </button>
  );
};
