import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { App } from "@/components/App/App";
import { MainPage } from "@/pages/main/MainPage";
import { FriendsPage } from "@/pages/friends/FriendsPage";
import { TasksPage } from "@/pages/tasks/TasksPage";
import { UnauthorizedPage } from "@/pages/unauthorized/UnauthorizedPage";

const routes = [
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "/friends",
        element: <FriendsPage />,
      },
      {
        path: "/tasks",
        element: <TasksPage />,
      },
      {
        path: "/unauthorized",
        element: <UnauthorizedPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
